import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";

export default class Pakete extends Standard {
  constructor() {
    super("#MiddleContainer");
    if (!super.exist()) return;

    this.hl = new Handlers();
    this.http = new ZachiHttp();
    this.count = 0;
    this.premium = super.set("#premium");
    this.basic = super.set("#basic");
    this.basicBack = super.set("#basicBack");
    this.basicContainer = super.set("#basicContainer");
    this.premiumContainer = super.set("#premiumContainer");
    this.endpoint = super.setEndpoint(
      "/pages_api/get_card/RANDOM?random_n=2&count="
    );

    // this.init();
  }

  async init() {
    // this.events();
    // const z = await this.get_one();
    // const x = z[0];
    // const y = z[1];
    // this.basic.src = this.setEndpoint(x.src);
    // this.premium.src = this.setEndpoint(y.src);
    // this.rarity();
    // this.cycle();
  }

  async rarity() {
    this.rarityContainer = document.querySelector("#rarity");
    if (this.rarityContainer === null) {
      return;
    }
    this.rarity1 = document.querySelector("#rarity_1");
    this.rarity2 = document.querySelector("#rarity_2");
    this.rarity3 = document.querySelector("#rarity_3");
    const z = await this.get_one();

    const x = z[0];
    this.rarity1.src = this.setEndpoint(x.src);
    this.rarity2.src = this.setEndpoint(x.src_1);
    this.rarity3.src = this.setEndpoint(x.src_2);
  }
  events() {
    this.basic.addEventListener("click", () => {
      this.oneRandom("basic");
    });

    this.premium.addEventListener("click", () => {
      this.oneRandom("premium");
    });
  }

  async get_one() {
    const res = await this.http.get(this.endpoint + this.count);
    this.count = Math.random();
    if (res.status === 200) {
      return [
        {
          id: res.cards[0].id,
          src: res.cards[0].imageSrc,
          src_1: res.cards[0].imageSrc_1,
          src_2: res.cards[0].imageSrc_2,
          link: "",
        },
        {
          id: res.cards[1].id,
          src: res.cards[1].imageSrc,
          src_1: res.cards[1].imageSrc_1,
          src_2: res.cards[1].imageSrc_2,
          link: "",
        },
      ];
    } else {
      console.table("error Paket get_one");
    }
  }

  async oneRandom(type = "basic") {
    const z = await this.get_one();
    const x = z[0];
    const y = z[1];
    const r = Math.random();
    this.basicContainer.classList.remove("active");
    this.premiumContainer.classList.remove("active");
    this.basic.classList.add("transparent");
    this.premium.classList.add("transparent");
    await this.hl.wait(800);
    if (r < 0.5) {
      this.premium.src = this.setEndpoint(y.src);
    } else if (r < 0.9) {
      this.premium.src = this.setEndpoint(y.src_1);
    } else {
      this.premium.src = this.setEndpoint(y.src_2);
    }
    this.basic.src = this.setEndpoint(x.src);

    await this.hl.wait(800);
    this.basicContainer.classList.add("active");
    this.premiumContainer.classList.add("active");
    this.basic.classList.remove("transparent");
    this.premium.classList.remove("transparent");
  }

  async cycle() {
    await this.hl.wait(4000);
    this.oneRandom("premium");
    this.oneRandom("basic");
    await this.hl.wait(4000);

    this.cycle();
  }
}
