import Standard from "../../js/Libraries/Standard";
import jump from "jump.js";

export default class Navbar extends Standard {
  constructor() {
    super("#z_navbar");
    if (!super.exist()) return;
    this.burger = super.set(".z-burger");
    this.pages = super.set(".z-nav-pages");
    this.navLinks = super.set(".z-nav-page", true);
    this.logo = super.set(".z-logo-container");
    this.speed = 0.65;
    this.distance_from_top = 95;
    this.allJumpTo = document.querySelectorAll(".jump-to-el-special");
    this.events();
    this.dropdown();
    this.small_btns();
  }

  small_btns() {
    const btns = super.set(".small-btn", true);
    btns.forEach((btn) => {
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        const parent = btn.parentNode.parentNode;
        parent.classList.toggle("active");
      });
    });
  }
  events() {
    this.burger.addEventListener("click", () => this.toggle());
    this.onClickLis();
  }

  toggle() {
    if (this.pages.dummy) {
      this.pages.style.animation = `navSlideOut ${this.speed}s ease backwards`;
      this.pages.dummy = "";
    } else {
      this.pages.style.animation = `navSlideIn ${this.speed}s ease forwards`;
      this.pages.dummy = "x";
    }
    this.burger.classList.toggle("z-toggle");
    this.navLinks.forEach((link, index) => {
      var speed_pro = index / 8 + this.speed - 0.35;
      if (link.style.animation) {
        link.style.animation = "";
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${speed_pro}s`;
      }
    });
  }

  onClickLis() {
    const lis = super.set(".big-screen .dropdown-element li", true);
    lis.forEach((li) => {
      const a = li.querySelector("a");
      const ref = a.getAttribute("hrefJump");
      li.addEventListener("click", (e) => {
        e.preventDefault();
        const goal = document.querySelector(ref);
        if (goal !== null) {
          jump(goal, {
            duration: (distance) => Math.min(Math.abs(distance) / 2, 1200),
            offset: -100,
          });
        } else {
          window.location.href = a.href;
        }
      });
    });

    this.allJumpTo.forEach((el) => {
      const ref = el.getAttribute("hrefJump");
      el.addEventListener("click", (e) => {
        e.preventDefault();
        const goal = document.querySelector(ref);
        if (goal !== null) {
          this.toggle();
          jump(goal, {
            duration: (distance) => Math.min(Math.abs(distance) / 2, 1200),
            offset: -100,
          });
        } else {
          window.location.href = el.href;
        }
      });
    });
  }

  dropdown() {
    const dropdown = super.set(".dropdown-element-1");
    const btn = super.set(".dropdown-btn-1");
    btn.addEventListener("mouseover", () => {
      dropdown.classList.add("active");
    });

    function outsideClick(e) {
      if (
        !(
          e.target.matches(".ul-1") ||
          e.target.matches(".nav-a-1") ||
          e.target.matches(".ul-1 li") ||
          e.target.matches(".dropdown-btn-1") ||
          e.target.matches(".z-nav-pages")
        )
      ) {
        dropdown.classList.remove("active");
      }
    }

    document.addEventListener("mouseover", (e) => outsideClick(e));
  }
}
