import Standard from "../../js/Libraries/Standard";

import Handlers from "../../js/Libraries/Handlers";
import VideoText from "./_VideoText";

class Video extends Standard {
  constructor() {
    super("#video");
    if (!super.exist()) return;

    new VideoText();

    this.hl = new Handlers();
    this.video = super.set("#videoTag");
    this.videoContainer = super.set(".video-container");
    this.play = super.set("#play");
    this.stop = super.set("#stop");
    this.progress = super.set("#progress");
    this.timestamp = super.set("#timestamp");
    this.page = this.container.getAttribute("data-page");
    this.firstLoad = false;

    this.init();
  }

  async init() {
    // this.generateSky();
    // this.generateStars();
    if (this.firstLoad === false) {
      if (this.hl.elementInViewportAnyPart(this.container)) {
        this.firstLoad = true;
        const src = this.video.getAttribute("data-src");
        this.video.src = src;
        this.toggleVideoStatus();
      }
    }
    this.events();
    this.checkClick();
  }

  generateSky() {
    const html = "<div class='sky'></div>";

    this.container.insertAdjacentHTML("beforeend", html);
    this.sky = super.set(".sky");
  }
  generateStars = (starNumber = 1200) => {
    const getStarInfo = () => ({
      x: this.getRandom(0, 100),
      y: this.getRandom(0, 100),
      opacity: this.getRandom(0.3, 1),
      size: this.getRandom(0.3, 2),
      delay: this.getRandom(0.3, 10),
    });

    let html = "";
    for (let i = 0; i < starNumber; i++) {
      const { x, y, opacity, size, delay } = getStarInfo();
      const style = `--x: ${x}%; --y: ${y}%; --opacity: ${opacity}; --size: ${size}; --delay: -${delay}s;`;
      html += `<div class='star' style='${style}'></div>`;
    }
    this.sky.insertAdjacentHTML("beforeend", html);
  };

  getRandom = (min = 0, max = 100, roundResult = false) => {
    const result = Math.random() * (max - min) + min;
    return roundResult ? Math.round(result) : result;
  };
  toggleVideoStatus() {
    if (this.video.paused) {
      this.video.play();
    } else {
      this.video.pause();
    }
  }

  playForwards() {
    if (this.video.paused) {
      this.video.play();
    } else {
    }
  }

  checkClick() {
    this.videoContainer.addEventListener("click", (e) => {
      e.preventDefault();
      if (this.page === "home") {
        window.location.href = "/coin";
      } else {
        this.toggleVideoStatus();
      }
    });
  }
  events() {
    document.addEventListener("scroll", async () => {
      if (this.firstLoad === false) {
        if (this.hl.elementInViewportAnyPart(this.container)) {
          this.firstLoad = true;
          const src = this.video.getAttribute("data-src");
          this.video.src = src;
          this.toggleVideoStatus();
        }
      }
    });
  }
}

new Video();
