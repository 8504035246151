import ImageSlidesPopUp from "../../js/Libraries/ImageSlidesPopUp";
import Contact from "./_Contact";
import Countdown from "./_Countdown";
import Decks from "./_Decks";

import Nordic from "./_Nordic";
import OneDeck from "./_OneDeck";
import Pakete from "./_Pakete";

new Decks();
new OneDeck();

new Countdown();

new Pakete();

new Nordic();

new Contact();

new ImageSlidesPopUp();

import "./_Video";
