import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";

export default class OneDeck extends Standard {
  constructor() {
    super("#OneDeck");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.isReleased = this.container.getAttribute("data-isReleased"); // 0 or 1
    this.category = this.container.getAttribute("data-category");
    this.readMores = super.set(".read-more", true);
    this.state = "COMMON"; // "RARE,EPIC"
    this.CommonBtn = super.set("#CommonBtn");
    this.RareBtn = super.set("#RareBtn");
    this.UltraRareBtn = super.set("#UltraRareBtn");
    this.flipcard = super.set("#flipcard");

    this.icons = super.set(".one-icon-2", true);
    this.numbers = super.set(".number", true);
    this.img_numbers = super.set(".img-number", true);
    this.mint = super.set("#MintText");
    this.mainImg = super.set("#mainImg");
    this.init();
  }

  init() {
    this.setPoints(this.state);
    if (this.state === "COMMON") {
      this.CommonBtn.classList.add("active");
    } else if (this.state === "RARE") {
      this.RareBtn.classList.add("active");
    } else {
      this.UltraRareBtn.classList.add("active");
    }

    this.setMint(this.state);
    this.events();
  }

  setPoints(state) {
    if (state === "COMMON") {
      this.change(state);
    } else if (state === "RARE") {
      this.change(state);
    } else {
      this.change(state);
    }
  }

  async change(state) {
    this.icons.forEach((el) => {
      el.classList.add("transparent");
    });
    this.flipcard.classList.remove("gold-border");
    this.flipcard.classList.remove("silver-border");
    await this.hl.wait(400);
    if (this.isReleased == 1) {
      if (state === "COMMON") {
        this.mainImg.src = this.mainImg.getAttribute("data-img");
      } else if (state === "RARE") {
        this.mainImg.src = this.mainImg.getAttribute("data-img_1");
      } else {
        this.mainImg.src = this.mainImg.getAttribute("data-img_2");
      }
    }

    this.numbers.forEach((el) => {
      el.innerText = el.getAttribute(state);
      el.classList.remove("silver");
      el.classList.remove("gold");
    });

    this.img_numbers.forEach((el) => {
      el.src = el.getAttribute(state);
    });
    await this.hl.wait(400);

    this.icons.forEach((el) => {
      el.classList.remove("transparent");
    });
    if (this.isReleased != 0) {
      if (state === "COMMON") {
      } else if (state === "RARE") {
        this.flipcard.classList.add("silver-border");
      } else {
        this.flipcard.classList.add("gold-border");
      }
    }

    await this.hl.wait(400);

    if (state === "COMMON") {
    } else if (state === "RARE") {
      this.numbers.forEach((el) => {
        el.classList.add("silver");
      });
    } else {
      this.numbers.forEach((el) => {
        el.classList.add("gold");
      });
    }
  }

  setMint(state) {
    if (state === "COMMON") {
      this.mint.innerText = "30.000 (COMMON)";
    } else if (state === "RARE") {
      this.mint.innerText = "3.000 (RARE)";
    } else {
      this.mint.innerText = "300 (EPIC)";
    }
  }

  events() {
    this.CommonBtn.addEventListener("click", () => {
      this.CommonBtn.classList.remove("active");
      this.RareBtn.classList.remove("active");
      this.UltraRareBtn.classList.remove("active");
      this.CommonBtn.classList.add("active");
      this.flipcard.classList.remove("gold-border__");
      this.flipcard.classList.remove("silver-border__");
      this.state = "COMMON";
      this.setPoints("COMMON");
      this.setMint("COMMON");
    });

    this.RareBtn.addEventListener("click", () => {
      this.CommonBtn.classList.remove("active");
      this.RareBtn.classList.remove("active");
      this.UltraRareBtn.classList.remove("active");
      this.RareBtn.classList.add("active");
      this.flipcard.classList.remove("gold-border__");
      this.flipcard.classList.add("silver-border__");
      this.state = "RARE";
      this.setPoints("RARE");
      this.setMint("RARE");
    });

    this.UltraRareBtn.addEventListener("click", () => {
      this.CommonBtn.classList.remove("active");
      this.RareBtn.classList.remove("active");
      this.UltraRareBtn.classList.remove("active");
      this.UltraRareBtn.classList.add("active");
      this.flipcard.classList.add("gold-border__");
      this.flipcard.classList.remove("silver-border__");
      this.state = "EPIC";
      this.setPoints("EPIC");
      this.setMint("EPIC");
    });

    this.readMores.forEach((el) => {
      el.addEventListener("click", () => {
        const text = el.getAttribute("data-content");
        this.hl.message(text);
      });
    });
  }
}
