import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';
import jump from 'jump.js';

export default class Nordic extends Standard {
  constructor() {
    super('body');
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.flipAnimations = super.set('.nordic-flip-animation', true);
    this.flipcards = super.set('.flipcard-2', true);
    this.gameUnder = document.querySelector('#game-under');

    this.gameStart = document.querySelector('#game-start');
    this.nordicBuyOptions = super.set('.nordic-buy-option', true);
    this.buyText = '';
    this.init();

    // this.hl.message(NordicBuyOption);
  }

  init() {
    this.setBuyText();
    this.animateHash();
    this.events();
    // this.notice();
  }

  async notice() {
    const text = ` Notice: As we are currently in an extended white-listing process with a major NFT platform, we have to point out the following: 
    We have the intention to create an NFT based game but for legal reasons we are not allowed to promise this until the implementation is finished and the white-listing is done.`;
    if (this.gameUnder !== null) {
      await this.hl.wait(2000);
      this.hl.message(text);
    }

    if (this.gameStart !== null) {
      var count = 0;
      document.addEventListener('scroll', async () => {
        if (this.hl.elementInViewportAnyPart(this.gameStart) && count === 0) {
          count += 1;
          await this.hl.wait(400);
          this.hl.message(text);
        }
      });
    }
  }

  async animateHash() {
    var goal = null;
    if (window.location.hash) {
      var goal = document.querySelector(window.location.hash);
    }
    if (goal !== null) {
      await this.hl.wait(500);
      jump(goal, {
        duration: (distance) => 400,
        offset: -100,
      });
    }
  }
  events() {
    document.addEventListener('scroll', async () => {
      this.flipAnimations.forEach(async (x) => {
        if (this.hl.elementInViewportAnyPart(x)) {
          await this.hl.wait(800);
          x.classList.remove('backside');
        } else {
          x.classList.add('backside');
        }
      });
    });

    this.flipcards.forEach((el) => {
      el.addEventListener('mouseenter', () => {
        el.classList.add('flip-action');
      });
      el.addEventListener('mouseleave', () => {
        el.classList.remove('flip-action');
      });
    });

    this.nordicBuyOptions.forEach((btn) => {
      btn.addEventListener('click', (e) => {
        e.preventDefault();
        // window.open('https://wax.atomichub.io/drops/89883+89884+89916', '_blank');
        this.hl.message(this.buyText);
      });
    });
  }

  setBuyText() {
    let linkNefty = document.body.getAttribute('data-nefty');

    let linkAtomic = document.body.getAttribute('data-atomic');
    let linkNFTHIVE = document.body.getAttribute('data-nfthive');

    this.buyText = `    

<div class="nordic-buy-container">
<div class="one-el">
  <a
    href="${linkNefty}"
    target="_blanket"
  >
    <div class="img-cont mb-4">
      <img
        class="img-buy"
        src="img/partner/logo-nefty.svg"
        alt="Logo Nefty - Partner from Lost Treasures Galaxy"
      />
    </div>
    <div class="text-center pt-4">
      <a
        href="${linkNefty}"
        target="_blanket"
        class="buy-now btn-mess"
      >
        Buy on NeftyBlocks
      </a>
    </div>
  </a>
</div>
<div class="one-el">
  <a
    href="${linkAtomic}"
    target="_blanket"
  >
    <div class="img-cont mb-4">
      <img
        class="img-buy atomic-img"
        src="img/partner/atomicHub.svg"
        alt="Logo AtomicHub - Partner from Lost Treasures Galaxy"
      />
    </div>
    <div class="text-center pt-4">
      <a
        href="${linkAtomic}"
        target="_blanket"
        class="buy-now btn-mess"
      >
        Buy on AtomicHub
      </a>
    </div>
  </a>
</div>
<div class="one-el">
  <a
    href="${linkNFTHIVE}"
    target="_blanket"
  >
    <div class="img-cont mb-4">
      <img
        class="img-buy"
        src="img/partner/nfthive-logo.svg"
        alt="Logo NFTHive - Partner from Lost Treasures Galaxy"
      />
    </div>
    <div class="text-center pt-4">
      <a
        href="${linkNFTHIVE}"
        target="_blanket"
        class="buy-now btn-mess"
      >
        Buy on NFTHive
      </a>
    </div>
  </a>
</div>
</div>


`;
  }
}
