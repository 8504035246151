import Standard from "../../js/Libraries/Standard";

import Handlers from "../../js/Libraries/Handlers";

export default class VideoText extends Standard {
  constructor() {
    // super("#VideoText");
    super("#video");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.lastItem = null;
    this.lastItem2 = null;
    this.lastWord = 0;
    this.lastWord2 = 0;
    this.words = words;
    this.magicSmall = super.set("#magicSmall");

    this.lastItemBig = null;
    this.lastItemBig2 = null;

    this.wordsSmall = this.shuffle(words);
    this.wordsBig = this.shuffle(words);

    this.init();
  }

  async init() {
    this.addStars(0);
    this.runMagicSmall();
    this.runMagicBig();
    // this.starAnimation();
    await this.hl.wait(1500);
    this.runMagicBig2();
  }

  addStars(number) {
    const leftSide = super.set(".left-side");
    const rightSide = super.set(".right-side");
    var starStringLeft = `
    <div class="magic-text link_to magic-text-big-left" link_to="/coin">
    xxx
  </div>
  <div class="magic-text link_to magic-text-big-left" link_to="/coin">
    xxx
  </div>
    `;

    for (let i = 0; i < number; i++) {
      starStringLeft += `    <div class="star">
     <i class="fas fa-star"></i>
   </div>`;
    }

    var starStringRight = `
    <div class="magic-text link_to magic-text-big-right" link_to="/coin">
    xxx
  </div>
  <div class="magic-text link_to magic-text-big-right" link_to="/coin">
    xxx
  </div>
    `;
    for (let i = 0; i < number; i++) {
      starStringRight += `    <div class="star">
     <i class="fas fa-star"></i>
   </div>`;
    }

    leftSide.innerHTML = starStringLeft;
    rightSide.innerHTML = starStringRight;
    this.stars = super.set(".star", true);

    this.link_to = this.container.querySelectorAll(".link_to");
    this.link_to.forEach((el) => {
      el.addEventListener("click", (e) => {
        e.preventDefault();
        let link = el.getAttribute("link_to");
        if (link !== null) {
          window.location.href = link;
        }
      });
    });
    this.magicBigs = super.set(".magic-text-big-right", true);
    this.magicBigs2 = super.set(".magic-text-big-left", true);
  }

  shuffle(array) {
    return array.slice().sort(() => Math.random() - 0.5);
  }

  randomText() {
    let item = this.lastWord + 1;
    if (item >= this.wordsSmall.length) {
      item = 0;
    }
    this.lastWord = item;

    return this.wordsSmall[item];
  }

  randomTextBig() {
    let item = this.lastWord2 + 1;
    if (item >= this.wordsBig.length) {
      item = 0;
    }
    this.lastWord2 = item;
    return this.wordsBig[item];
  }

  randomItemBig() {
    let item = Math.floor(Math.random() * this.magicBigs.length);
    while (item === this.lastItemBig) {
      item = Math.floor(Math.random() * this.magicBigs.length);
    }
    this.lastItemBig = item;
    return this.magicBigs[item];
  }

  randomItemBig2() {
    let item = Math.floor(Math.random() * this.magicBigs2.length);
    while (item === this.lastItemBig2) {
      item = Math.floor(Math.random() * this.magicBigs2.length);
    }
    this.lastItemBig2 = item;
    return this.magicBigs2[item];
  }

  async runMagicSmall() {
    await this.hl.wait(1000);
    this.magicSmall.innerText = this.randomText();
    await this.hl.wait(1000);
    this.magicSmall.classList.add("fade-in");
    await this.hl.wait(2000);
    this.magicSmall.classList.remove("fade-in");
    this.runMagicSmall();
  }

  async hideBig() {
    this.magicBigs.forEach((x) => {
      x.classList.remove("fade-in");
    });
    await this.hl.wait(1000);
  }
  async setBig() {
    const el = this.randomItemBig();
    const marginTop = Math.floor(Math.random() * 35);
    const marginLeft = Math.floor(Math.random() * 35);
    el.innerText = this.randomTextBig();
    el.style.marginTop = marginTop + "%";
    el.style.marginLeft = marginLeft + "%";
    await this.hl.wait(1000);
    el.classList.add("fade-in");
  }

  async runMagicBig() {
    await this.hl.wait(1000);
    await this.hideBig();
    await this.setBig();
    await this.hl.wait(1000);
    this.runMagicBig();
  }

  async hideBig2() {
    this.magicBigs2.forEach((x) => {
      x.classList.remove("fade-in");
    });
    await this.hl.wait(1000);
  }
  async setBig2() {
    const el = this.randomItemBig2();
    const marginTop = Math.floor(Math.random() * 35);
    const marginLeft = Math.floor(Math.random() * 35);
    el.innerText = this.randomText();
    el.style.marginTop = marginTop + "%";
    el.style.marginLeft = marginLeft + "%";
    await this.hl.wait(1000);
    el.classList.add("fade-in");
  }

  async runMagicBig2() {
    await this.hl.wait(1000);
    await this.hideBig2();
    await this.setBig2();
    await this.hl.wait(1000);
    this.runMagicBig2();
  }

  starAnimation() {
    this.stars.forEach((star) => {
      this.oneStarAnimation(star);
    });
  }

  async oneStarAnimation(star) {
    const time0 = Math.floor(Math.random() * 4000);
    const marginTop = Math.floor(Math.random() * 100) - 5;
    const marginLeft = Math.floor(Math.random() * 100) - 5;
    star.style.top = marginTop + "%";
    star.style.left = marginLeft + "%";
    await this.hl.wait(time0);

    const time = Math.floor(Math.random() * 12000);
    const time1 = Math.max(time, 4000);
    await this.hl.wait(1000);
    star.classList.add("fade-in");
    await this.hl.wait(time1);
    star.classList.remove("fade-in");

    const time2 = Math.max(4000, Math.floor(Math.random() * 8000));
    await this.hl.wait(time2);
    this.oneStarAnimation(star);
  }
}

const words = [
  "Unlock your Treasure Hunt",
  "Benefits of being a LTG-Coin Holder",
  "Your Entry-Ticket to special Events",
  "Multiple Free NFT Drops for LTG-Coin Owners",
  "Claim your right to participation",
  "Metaverse Galaxy",
  "Pick up your LTG-Coin now",
  "One LTG-Coin = One Voice",
  "One LTG-Coin = One Solution",
  "Special Rewards for LTG-Coin Owners",
];
