import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";

export default class Decks extends Standard {
  constructor() {
    super("#Decks");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.readMores = super.set(".read-more", true);
    this.flipAnimations = super.set(".flip-animation", true);
    this.init();
  }

  init() {
    this.events();
  }
  events() {
    this.readMores.forEach((el) => {
      el.addEventListener("click", () => {
        const text = el.getAttribute("data-content");
        this.hl.message(text);
      });
    });

    this.flipAnimations.forEach((x) => {});

    document.addEventListener("scroll", async () => {
      this.flipAnimations.forEach(async (x) => {
        if (this.hl.elementInViewportAnyPart(x)) {
          await this.hl.wait(400);
          x.classList.remove("backside");
        } else {
          x.classList.add("backside");
        }
      });
    });
  }
}
