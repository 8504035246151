import Standard from "../../js/Libraries/Standard";
import ZachiHttp from "../../js/Libraries/ZachiHttp";
import Handlers from "../../js/Libraries/Handlers";

export default class Countdown extends Standard {
  constructor() {
    super("#Countdown");
    if (!super.exist()) return;
    this.hl = new Handlers();
    this.seconds = super.set("#seconds");
    this.minutes = super.set("#minutes");
    this.hours = super.set("#hours");
    this.days = super.set("#days");
    this.countdownEl = super.set("#countdownEl");
    this.headline = super.set("#headline");
    this.successContent = super.set("#successContent");
    this.goal = this.container.getAttribute("data-date");
    this.countDown = new Date(this.goal).getTime();

    this.init();
  }

  init() {
    // this.action();
    this.action();
  }

  action() {
    this.oneAction();
    const x = setInterval(() => {
      const distance = this.oneAction();
      //do something later when date is reached
      if (distance < 0) {
        this.headline.style.display = "none";
        this.countdownEl.style.display = "none";
        this.successContent.style.display = "block";
        clearInterval(x);
      }
      //seconds
    }, 500);
  }

  oneAction() {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;

    const now = new Date().getTime();
    const distance = this.countDown - now;
    this.days.innerText = this.addZero(Math.floor(distance / day));
    this.hours.innerText = this.addZero(Math.floor((distance % day) / hour));
    this.minutes.innerText = this.addZero(
      Math.floor((distance % hour) / minute)
    );
    this.seconds.innerText = this.addZero(
      Math.floor((distance % minute) / second)
    );
    return distance;
  }

  addZero(x) {
    if (x < 10) {
      return "0" + x;
    } else {
      return x;
    }
  }
}
