import Back2Top from "./Back2Top.js";
import Navbar from "./_Navbar.js";
import GET_Message from "./_GET_Message.js";

import Links from "./_Links.js";
import Accordins from "./_Accordions.js";
import AllGlider from "./_AllGlider.js";

new Navbar();
new Back2Top();

new GET_Message();

new Links();

new Accordins();
new AllGlider();

const footer_year = document.querySelector("#footer_year");
if (footer_year !== null) {
  var date = new Date();
  footer_year.innerText = date.getFullYear();
}
